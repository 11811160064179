.formStyles {
    border: solid 3px blue;
    margin: 10em auto;
    width: 90%;
}
.button {
    background-color: #e5c220;
    height: 3em;
    color: #ffffff;
    margin-top: 3em;
    border-radius: 5px;
    width: 9em;
}
